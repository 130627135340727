import * as React from 'react';

import './PopInVideoYtb.scss';
import imgBg from '../../../assets/images/popInBg.jpg';
import { CrossIcon, LineIcon } from '../../../Atoms/Icons/Icons';
import { youtube_parser } from '../../../Utils/Utils';
import VideoYoutube from '../../Video/VideoYoutube';

function PopInVideoYtb({ videoLink }) {
    const [videourl, setVideour] = React.useState(videoLink);
    const [stopVideo, setStopVideo] = React.useState(false);

    function closeVid() {
        document.querySelector('.popin_wrapper').style.display = 'none';
        document.getElementsByTagName('html')[0].style.overflow = 'scroll';

        let iframes = document.querySelectorAll('iframe');
        Array.prototype.forEach.call(iframes, iframe => {
            iframe.contentWindow.postMessage(JSON.stringify({
                event: 'command',
                func: 'stopVideo'
            }), '*');
        });
    }

    return (
        <div className='popin_wrapper' >
            <img src={imgBg} />
            <div className="close_wrap" >
                <CrossIcon onClick={() => { closeVid() }} />
            </div>
            <div className="bottom_line_wrap">
                <LineIcon />
            </div>

            <div className='video_Wrapper'>
                <VideoYoutube
                    //cover={imgBg}
                    videoID={youtube_parser(videourl)}
                    videoTitle={'This Video'}
                    stop={stopVideo}
                    obj={{
                        height: '390',
                        width: '640',
                        playerVars: {
                            // https://developers.google.com/youtube/player_parameters
                            autoplay: 0,
                            controls: 0,
                            disablekb: 1,
                            rel: 0,
                            showinfo: 0,
                        },
                    }}
                />

            </div>

        </div>
    );
}

export default PopInVideoYtb;
