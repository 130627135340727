import React from 'react';

import './EngagementSoloContainer.scss';

const EngagementSoloContainer = ({ icon, text }) => {
  return (
    <div className="engagement_container">
      <div className="container">
        <img className='img' src={icon} />
        <p>{text}</p>
      </div>
    </div>
  );
};

export default EngagementSoloContainer;
