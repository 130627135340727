import * as React from 'react';

import './TextContent.scss';
import Logo from '../../assets/images/act-logo-colas.png';
import ShapeBackground from '../../Molecules/ShapeBackground/ShapeBackground';
import TitleSection from '../../Molecules/TitleSection/TitleSection';

function TextContent({ block, productTitle }) {
  return (

    <section className='section_content_no_marge table_wrapper'>
      <ShapeBackground top={block?.field_display_background ? 'left' : null} color={block?.field_display_background ? 'catskill_white' : 'white'} bottom={block?.field_display_background ? 'left' : null}>
        <div className='wrapper_page'>
          <TitleSection
            h3color='dark_bleu'
            title={block?.field_display_as_main_title ? `<span class='prod-title'>${productTitle}</span>` : (block?.field_display_solution_name ? `${block?.relationships?.field_title?.name} <span class='prod-title'>${productTitle}</span>` : block?.relationships?.field_title?.name)}
            type={block?.field_display_as_main_title ? 'line' : 'arrow'}
          />
          {
            block?.body.length > 1 ? (
              <div className={'txt-wrapper table-wrap ' + (block?.field_display_as_main_title ? 'line_paddding' : '')}>
                {
                  block?.body?.map((text, i) => (
                    <div key={i} dangerouslySetInnerHTML={{ __html: text.processed }} />

                  ))
                }

                {block?.field_display_logo && <div className='img_logo'><img src={Logo} alt='act' /></div>}
              </div>
            ) : (

              block?.field_display_column ? (
                <div className={'txt-wrapper ' + (block?.field_display_as_main_title ? 'line_paddding' : '')}>
                  <div dangerouslySetInnerHTML={{ __html: block?.body[0]?.processed }} />
                </div>
              ) : (
                <div className={!block?.field_display_solution_name && !block?.field_display_background ? 'table-wrap small_width' : 'table-wrap' + (block?.field_display_as_main_title ? ' line_paddding' : '')}>
                  <div dangerouslySetInnerHTML={{ __html: block?.body[0]?.processed }} />
                </div>
              )

            )
          }

        </div>
      </ShapeBackground>
    </section>
  );
}

export default TextContent;
