import React from 'react';

import ShapeBackground from '../../Molecules/ShapeBackground/ShapeBackground';
import EngagementSoloContainer from './EngagementSoloContainer/EngagementSoloContainer';

import './EngagementContainer.scss';

const EngagementContainer = ({ engagements_list, EngagementTitle }) => {
  return (
    <section className='section_content engagements_main_wrapper'>
      <ShapeBackground
        top={'right'}
        color={'dark_bleu'}
      >
        <div className="wrapper_page">
          <div className="engagements_title_wrapper">
            <h1>{EngagementTitle}</h1>
          </div>
          <div className="engagements_container">

            {engagements_list.map((engagements, i) => (
              <EngagementSoloContainer key={i} icon={engagements.icon} text={engagements.text} />
            ))}

          </div>
        </div>
      </ShapeBackground>

    </section>

  );
};

export default EngagementContainer;
