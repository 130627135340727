import React from 'react';

import { graphql } from 'gatsby';

import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n/src';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import CardSection from '../../components/ContentType/Section/CardSection/CardSection';
import EngagementContainer from '../../components/EngagementContainer/EngagementContainer';
import PageBanner from '../../components/PageBanner/PageBanner';
import PopInVideoYtb from '../../components/PopIn/PopinVideoYtb/PopInVideoYtb';
import Seo from '../../components/Seo/Seo';
import Slider from '../../components/Slider/Slider';
import SliderBullet from '../../components/SliderBullet/SliderBullet';
import TextContent from '../../components/TextContent/TextContent';
import useMedias from '../../hooks/useMedias';
import Layout from '../../Layout';
import TitlePage from '../../Molecules/TitlePage/TitlePage';
import {
  getBreadCrumb
} from '../../Utils/Utils';

import './styles.scss';
let classNames = require('classnames');

const ProjectsPage = ({ data }) => {
  const intl = useIntl();
  const { getImage } = useMedias();

  const imagesArray = data?.allImages?.edges;
  const pageData = data?.pageData?.edges[0]?.node;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, pageData?.path?.alias);
  const metaTags = pageData.metatag;
  let metaTitle = '';
  let metaDesc = '';

  metaTags.forEach((meta) => {
    if (meta.attributes.name === 'title') {
      metaTitle = meta.attributes.content;
    }
    if (meta.attributes.name === 'description') {
      metaDesc = meta.attributes.content;
    }
  });
  let listArr = [];

  return (
    <Layout imagesArray={imagesArray}>
      <Seo
        title={pageData?.field_metatag?.title ? pageData?.field_metatag?.title : metaTitle}
        description={pageData?.field_metatag?.description ? pageData?.field_metatag?.description : metaDesc}

      />
      <>
        <div
          className={classNames(
            'page_template',
            'page_default'
          )}
        >

          <PageBanner
            visuel={getImage(
              imagesArray,
              pageData?.relationships?.field_image?.drupal_internal__mid
            )}
            scroll={true}
            reverse={true}
            hasVideo={pageData?.field_video?.uri ? true : false}
          >
            <div className="wrapper_page">
              <Breadcrumb
                page_banner={true}
                data={{
                  parentPage: {
                    title: breadCrumb?.parent?.name,
                    url: breadCrumb?.parent?.link,
                  },
                  currentPage: {
                    title: breadCrumb?.current?.name,
                    url: breadCrumb?.current?.link,
                  },
                  locale: pageData?.langcode,
                }}
              />
              <TitlePage
                color="color_white"
                title={pageData?.title}
                description={pageData?.field_description?.processed}
              />

            </div>
          </PageBanner>
          <div className="background_white">
            <div className='solution_wrapper'>
              {
                pageData?.relationships?.field_blocs?.map((block, i) => {
                  switch (block.__typename) {
                    case 'block_content__solutions':

                      return (
                        <div key={i} >
                          <CardSection imagesArray={imagesArray} section={block} counter={i} listType='solutions' nosSolution={false} hasBg={block?.field_display_background ? true : false} topBg={block?.field_display_background ? true : false} customClass='project_card' />
                        </div>
                      );

                    case 'block_content__slider':
                      return (
                        block.field_theme_bullets ? <SliderBullet
                          sliderBloc={block}
                          imagesArray={imagesArray}
                          key={i}
                        />
                          :
                          <Slider
                            sliderBloc={block}
                            imagesArray={imagesArray}
                            key={i}
                          />
                      );

                    case 'block_content__engagements':
                      listArr = [];
                      block?.relationships?.field_engagement?.forEach(element => {
                        listArr.push({
                          icon: getImage(imagesArray, element?.relationships?.field_image?.drupal_internal__mid)?.image_style_uri?.gatsby_thumbnail,
                          text: element?.name
                        });
                      });

                      return (
                        <EngagementContainer engagements_list={listArr} EngagementTitle={block?.relationships?.field_title?.name} />
                      );

                    case 'block_content__texte':

                      return (
                        <TextContent block={block} productTitle={pageData?.title} />
                      );
                  }
                })
              }
            </div>
          </div>
        </div>
      </>
      <PopInVideoYtb videoLink={pageData?.field_video?.uri} />
    </Layout>
  );
};

export const query = graphql`
  query projectsTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
  ) {
    pageData: allNodeProjets(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
          field_description {
            processed
          }
          title
          field_video {
            uri
          }
          field_metatag{
            title
            description
          }
          metatag {
            attributes {
              content
              name
            }
          }
          relationships {
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__solutions {
                  field_subtitle {
                    processed
                  }
                  relationships {
                    field_title {
                      name
                    }
                    field_solution {
                      field_subtitle
                      field_cta
                      field_description {
                        processed
                      }
                      title
                      field_title_formatted { 
                        processed
                      }
                      field_lien{
                        uri															
                        url
                      }
                      path {
                        langcode
                        alias
                      }
                      relationships {
                        field_image {
                          drupal_internal__mid
                        }
                      }
                    }
                  }
                  field_display_background
                }
                ... on block_content__engagements {
                  relationships {
                    field_title {
                      name
                    }
                    field_engagement {
                      name
                      relationships {
                        field_image {
                          drupal_internal__mid
                        }
                      }
                    }
                  }
                }
                ... on block_content__texte {
                  field_display_logo
                  field_display_column
                  field_display_solution_name
                  field_display_as_main_title
                  field_display_background
                  body {
                    processed
                  }
                  relationships {
                    field_title {
                      name
                    }
                  }
                }
              }
            }
            field_image {
              drupal_internal__mid
            }
          }
        }
      }
    }

    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
              }
            }
          }
        }
      }
    }

    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }

  }
`;

export default ProjectsPage;
